<template>
	<div class="home">
		<subsidiary-analysis-request></subsidiary-analysis-request>
	</div>
</template>

<script>
import subsidiaryAnalysis from '@/components/pages/report/subsidiarybyanalysis/subsidiaryAnalysisRequest.vue';

export default {
	components: {
		'subsidiary-analysis-request': subsidiaryAnalysis,
	}
};
</script>

<style>

</style>
